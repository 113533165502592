import React from 'react';
import {PrivateRoute} from "../components/PrivateRoute";
import withGaTracker from "../hooks/withGaTracker";
import Tiempos from "../pages/Tiempos";
import TiemposDetalle from "../pages/Tiempos/detalle";
import Home from "../pages/Home";
import VacacionesLibres from "../pages/VacacionesLibres";
import Categorias from "../pages/Categorias";
import Ficheros from "../pages/Ficheros";
import Pausas from "../pages/Pausas";


const TrabajadorRoutes = props => <>
    <PrivateRoute path="/tiempos" exactly component={withGaTracker(Tiempos)}  />
    <PrivateRoute path="/tiempo/:id" exactly component={withGaTracker(TiemposDetalle)} />
    <PrivateRoute path="/vacaciones-libres" exactly component={withGaTracker(VacacionesLibres)} />

    <PrivateRoute path="/categorias" exactly component={withGaTracker(Categorias)} />
    <PrivateRoute path="/categoria/:id/:title" exactly component={withGaTracker(Ficheros)} />
    <PrivateRoute exact path="/" exactly component={withGaTracker(Home)} />
    <PrivateRoute exact path="/pausas" exactly component={withGaTracker(Pausas)} />
</>;

export default TrabajadorRoutes;
